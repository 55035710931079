@charset "UTF-8";

/* node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css */
.filepond--image-preview-markup {
  position: absolute;
  left: 0;
  top: 0;
}
.filepond--image-preview-wrapper {
  z-index: 2;
}
.filepond--image-preview-overlay {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 5rem;
  max-height: 7rem;
  margin: 0;
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filepond--image-preview-overlay svg {
  width: 100%;
  height: auto;
  color: inherit;
  max-height: inherit;
}
.filepond--image-preview-overlay-idle {
  mix-blend-mode: multiply;
  color: rgba(40, 40, 40, 0.85);
}
.filepond--image-preview-overlay-success {
  mix-blend-mode: normal;
  color: rgba(54, 151, 99, 1);
}
.filepond--image-preview-overlay-failure {
  mix-blend-mode: normal;
  color: rgba(196, 78, 71, 1);
}
@supports (-webkit-marquee-repetition: infinite) and ((-o-object-fit: fill) or (object-fit: fill)) {
  .filepond--image-preview-overlay-idle {
    mix-blend-mode: normal;
  }
}
.filepond--image-preview-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  border-radius: 0.45em;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.01);
}
.filepond--image-preview {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: #222;
  will-change: transform, opacity;
}
.filepond--image-clip {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
.filepond--image-clip[data-transparency-indicator=grid] img,
.filepond--image-clip[data-transparency-indicator=grid] canvas {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' fill='%23eee'%3E%3Cpath d='M0 0 H50 V50 H0'/%3E%3Cpath d='M50 50 H100 V100 H50'/%3E%3C/svg%3E");
  background-size: 1.25em 1.25em;
}
.filepond--image-bitmap,
.filepond--image-vector {
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
}
.filepond--root[data-style-panel-layout~=integrated] .filepond--image-preview-wrapper {
  border-radius: 0;
}
.filepond--root[data-style-panel-layout~=integrated] .filepond--image-preview {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
  border-radius: 99999rem;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-overlay {
  top: auto;
  bottom: 0;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.filepond--root[data-style-panel-layout~=circle] .filepond--file .filepond--file-action-button[data-align*=bottom]:not([data-align*="center"]) {
  margin-bottom: 0.325em;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--file [data-align*=left] {
  left: calc(50% - 3em);
}
.filepond--root[data-style-panel-layout~=circle] .filepond--file [data-align*=right] {
  right: calc(50% - 3em);
}
.filepond--root[data-style-panel-layout~=circle] .filepond--progress-indicator[data-align*=bottom][data-align*=left],
.filepond--root[data-style-panel-layout~=circle] .filepond--progress-indicator[data-align*=bottom][data-align*=right] {
  margin-bottom: calc(0.325em + 0.1875em);
}
.filepond--root[data-style-panel-layout~=circle] .filepond--progress-indicator[data-align*=bottom][data-align*=center] {
  margin-top: 0;
  margin-bottom: 0.1875em;
  margin-left: 0.1875em;
}

/* node_modules/filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css */
.filepond--media-preview audio {
  display: none;
}
.filepond--media-preview .audioplayer {
  width: calc(100% - 1.4em);
  margin: 2.3em auto auto;
}
.filepond--media-preview .playpausebtn {
  margin-top: 0.3em;
  margin-right: 0.3em;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  outline: none;
  cursor: pointer;
}
.filepond--media-preview .playpausebtn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.filepond--media-preview .play {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAyElEQVQ4T9XUsWoCQRRG4XPaFL5SfIy8gKYKBCysrax8Ahs7qzQ2qVIFOwsrsbEWLEK6EBFGBrIQhN2d3dnGgalm+Jh7789Ix8uOPe4YDCH0gZ66atKW0pJDCE/AEngDXtRjCpwCRucbGANzNVTBqWBhfAJDdV+GNgWj8wtM41bPt3AbsDB2f69d/0dzwC0wUDe54A8wAWbqJbfkD+BZPeQO5QsYqYu6LKb0MIb7VT3VYfG8CnwEHtT3FKi4c8e/TZMyk3LYFrwCgMdHFbRDKS8AAAAASUVORK5CYII=);
}
.filepond--media-preview .pause {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAh0lEQVQ4T+2UsQkCURBE30PLMbAMMResQrAPsQ0TK9AqDKxGZeTLD74aGNwlhzfZssvADDMrPcOe+RggYZIJcG2s2KinMidZAvu6u6uzT8u+JCeZArfmcKUeK+EaONTdQy23bxgJX8aPHvIHsSnVuzTx36rn2pQFsGuqN//ZlK7vbIDvq6vkJ9yteBXzecYbAAAAAElFTkSuQmCC);
}
.filepond--media-preview .timeline {
  width: calc(100% - 2.5em);
  height: 3px;
  margin-top: 1em;
  float: left;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.3);
}
.filepond--media-preview .playhead {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-top: -5px;
  background: white;
}
.filepond--media-preview-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  border-radius: 0.45em;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.01);
  pointer-events: auto;
}
.filepond--media-preview-wrapper:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2em;
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#00000000", GradientType=0);
  z-index: 3;
}
.filepond--media-preview {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  will-change: transform, opacity;
}
.filepond--media-preview video,
.filepond--media-preview audio {
  width: 100%;
  will-change: transform;
}

/* node_modules/filepond/dist/filepond.min.css */
.filepond--assistant {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}
.filepond--browser.filepond--browser {
  position: absolute;
  margin: 0;
  padding: 0;
  left: 1em;
  top: 1.75em;
  width: calc(100% - 2em);
  opacity: 0;
  font-size: 0;
}
.filepond--data {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  visibility: hidden;
  pointer-events: none;
  contain: strict;
}
.filepond--drip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: .1;
  pointer-events: none;
  border-radius: .5em;
  background: rgba(0, 0, 0, .01);
}
.filepond--drip-blob {
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 8em;
  height: 8em;
  margin-left: -4em;
  margin-top: -4em;
  background: #292625;
  border-radius: 50%;
}
.filepond--drip-blob,
.filepond--drop-label {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform, opacity;
}
.filepond--drop-label {
  right: 0;
  margin: 0;
  color: #4f4f4f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filepond--drop-label.filepond--drop-label label {
  display: block;
  margin: 0;
  padding: .5em;
}
.filepond--drop-label label {
  cursor: default;
  font-size: .875em;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
}
.filepond--label-action {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  -webkit-text-decoration-color: #a7a4a4;
  text-decoration-color: #a7a4a4;
  cursor: pointer;
}
.filepond--root[data-disabled] .filepond--drop-label label {
  opacity: .5;
}
.filepond--file-action-button.filepond--file-action-button {
  font-size: 1em;
  width: 1.625em;
  height: 1.625em;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  will-change: transform, opacity;
}
.filepond--file-action-button.filepond--file-action-button span {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}
.filepond--file-action-button.filepond--file-action-button svg {
  width: 100%;
  height: 100%;
}
.filepond--file-action-button.filepond--file-action-button:after {
  position: absolute;
  left: -.75em;
  right: -.75em;
  top: -.75em;
  bottom: -.75em;
  content: "";
}
.filepond--file-action-button {
  cursor: auto;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .5);
  background-image: none;
  box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0);
  transition: box-shadow .25s ease-in;
}
.filepond--file-action-button:focus,
.filepond--file-action-button:hover {
  box-shadow: 0 0 0 .125em hsla(0, 0%, 100%, .9);
}
.filepond--file-action-button[disabled] {
  color: hsla(0, 0%, 100%, .5);
  background-color: rgba(0, 0, 0, .25);
}
.filepond--file-action-button[hidden] {
  display: none;
}
.filepond--action-edit-item.filepond--action-edit-item {
  width: 2em;
  height: 2em;
  padding: .1875em;
}
.filepond--action-edit-item.filepond--action-edit-item[data-align*=center] {
  margin-left: -.1875em;
}
.filepond--action-edit-item.filepond--action-edit-item[data-align*=bottom] {
  margin-bottom: -.1875em;
}
.filepond--action-edit-item-alt {
  border: none;
  line-height: inherit;
  background: transparent;
  font-family: inherit;
  color: inherit;
  outline: none;
  padding: 0;
  margin: 0 0 0 .25em;
  pointer-events: all;
  position: absolute;
}
.filepond--action-edit-item-alt svg {
  width: 1.3125em;
  height: 1.3125em;
}
.filepond--action-edit-item-alt span {
  font-size: 0;
  opacity: 0;
}
.filepond--file-info {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: 0 .5em 0 0;
  min-width: 0;
  will-change: transform, opacity;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filepond--file-info * {
  margin: 0;
}
.filepond--file-info .filepond--file-info-main {
  font-size: .75em;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.filepond--file-info .filepond--file-info-sub {
  font-size: .625em;
  opacity: .5;
  transition: opacity .25s ease-in-out;
  white-space: nowrap;
}
.filepond--file-info .filepond--file-info-sub:empty {
  display: none;
}
.filepond--file-status {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  min-width: 2.25em;
  text-align: right;
  will-change: transform, opacity;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filepond--file-status * {
  margin: 0;
  white-space: nowrap;
}
.filepond--file-status .filepond--file-status-main {
  font-size: .75em;
  line-height: 1.2;
}
.filepond--file-status .filepond--file-status-sub {
  font-size: .625em;
  opacity: .5;
  transition: opacity .25s ease-in-out;
}
.filepond--file-wrapper.filepond--file-wrapper {
  border: none;
  margin: 0;
  padding: 0;
  min-width: 0;
  height: 100%;
}
.filepond--file-wrapper.filepond--file-wrapper > legend {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}
.filepond--file {
  position: static;
  display: flex;
  height: 100%;
  align-items: flex-start;
  padding: .5625em;
  color: #fff;
  border-radius: .5em;
}
.filepond--file .filepond--file-status {
  margin-left: auto;
  margin-right: 2.25em;
}
.filepond--file .filepond--processing-complete-indicator {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 3;
}
.filepond--file .filepond--file-action-button,
.filepond--file .filepond--processing-complete-indicator,
.filepond--file .filepond--progress-indicator {
  position: absolute;
}
.filepond--file [data-align*=left] {
  left: .5625em;
}
.filepond--file [data-align*=right] {
  right: .5625em;
}
.filepond--file [data-align*=center] {
  left: calc(50% - .8125em);
}
.filepond--file [data-align*=bottom] {
  bottom: 1.125em;
}
.filepond--file [data-align=center] {
  top: calc(50% - .8125em);
}
.filepond--file .filepond--progress-indicator {
  margin-top: .1875em;
}
.filepond--file .filepond--progress-indicator[data-align*=right] {
  margin-right: .1875em;
}
.filepond--file .filepond--progress-indicator[data-align*=left] {
  margin-left: .1875em;
}
[data-filepond-item-state*=error] .filepond--file-info,
[data-filepond-item-state*=invalid] .filepond--file-info,
[data-filepond-item-state=cancelled] .filepond--file-info {
  margin-right: 2.25em;
}
[data-filepond-item-state~=processing] .filepond--file-status-sub {
  opacity: 0;
}
[data-filepond-item-state~=processing] .filepond--action-abort-item-processing ~ .filepond--file-status .filepond--file-status-sub {
  opacity: .5;
}
[data-filepond-item-state=processing-error] .filepond--file-status-sub {
  opacity: 0;
}
[data-filepond-item-state=processing-error] .filepond--action-retry-item-processing ~ .filepond--file-status .filepond--file-status-sub {
  opacity: .5;
}
[data-filepond-item-state=processing-complete] .filepond--action-revert-item-processing svg {
  -webkit-animation: fall .5s linear .125s both;
  animation: fall .5s linear .125s both;
}
[data-filepond-item-state=processing-complete] .filepond--file-status-sub {
  opacity: .5;
}
[data-filepond-item-state=processing-complete] .filepond--file-info-sub,
[data-filepond-item-state=processing-complete] .filepond--processing-complete-indicator:not([style*=hidden]) ~ .filepond--file-status .filepond--file-status-sub {
  opacity: 0;
}
[data-filepond-item-state=processing-complete] .filepond--action-revert-item-processing ~ .filepond--file-info .filepond--file-info-sub {
  opacity: .5;
}
[data-filepond-item-state*=error] .filepond--file-wrapper,
[data-filepond-item-state*=error] .filepond--panel,
[data-filepond-item-state*=invalid] .filepond--file-wrapper,
[data-filepond-item-state*=invalid] .filepond--panel {
  -webkit-animation: shake .65s linear both;
  animation: shake .65s linear both;
}
[data-filepond-item-state*=busy] .filepond--progress-indicator svg {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translateX(-.0625em);
    transform: translateX(-.0625em);
  }
  20%, 80% {
    -webkit-transform: translateX(.125em);
    transform: translateX(.125em);
  }
  30%, 50%, 70% {
    -webkit-transform: translateX(-.25em);
    transform: translateX(-.25em);
  }
  40%, 60% {
    -webkit-transform: translateX(.25em);
    transform: translateX(.25em);
  }
}
@keyframes shake {
  10%, 90% {
    -webkit-transform: translateX(-.0625em);
    transform: translateX(-.0625em);
  }
  20%, 80% {
    -webkit-transform: translateX(.125em);
    transform: translateX(.125em);
  }
  30%, 50%, 70% {
    -webkit-transform: translateX(-.25em);
    transform: translateX(-.25em);
  }
  40%, 60% {
    -webkit-transform: translateX(.25em);
    transform: translateX(.25em);
  }
}
@-webkit-keyframes fall {
  0% {
    opacity: 0;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes fall {
  0% {
    opacity: 0;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.filepond--hopper[data-hopper-state=drag-over] > * {
  pointer-events: none;
}
.filepond--hopper[data-hopper-state=drag-over]:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.filepond--progress-indicator {
  z-index: 103;
}
.filepond--file-action-button {
  z-index: 102;
}
.filepond--file-status {
  z-index: 101;
}
.filepond--file-info {
  z-index: 100;
}
.filepond--item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0;
  margin: .25em;
  will-change: transform, opacity;
}
.filepond--item > .filepond--panel {
  z-index: -1;
}
.filepond--item > .filepond--panel .filepond--panel-bottom {
  box-shadow: 0 .0625em .125em -.0625em rgba(0, 0, 0, .25);
}
.filepond--item > .filepond--file-wrapper,
.filepond--item > .filepond--panel {
  transition: opacity .15s ease-out;
}
.filepond--item[data-drag-state] {
  cursor: -webkit-grab;
  cursor: grab;
}
.filepond--item[data-drag-state] > .filepond--panel {
  transition: box-shadow .125s ease-in-out;
  box-shadow: 0 0 0 transparent;
}
.filepond--item[data-drag-state=drag] {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.filepond--item[data-drag-state=drag] > .filepond--panel {
  box-shadow: 0 .125em .3125em rgba(0, 0, 0, .325);
}
.filepond--item[data-drag-state]:not([data-drag-state=idle]) {
  z-index: 2;
}
.filepond--item-panel {
  background-color: #64605e;
}
[data-filepond-item-state=processing-complete] .filepond--item-panel {
  background-color: #369763;
}
[data-filepond-item-state*=error] .filepond--item-panel,
[data-filepond-item-state*=invalid] .filepond--item-panel {
  background-color: #c44e47;
}
.filepond--item-panel {
  border-radius: .5em;
  transition: background-color .25s;
}
.filepond--list-scroller {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  will-change: transform;
}
.filepond--list-scroller[data-state=overflow] .filepond--list {
  bottom: 0;
  right: 0;
}
.filepond--list-scroller[data-state=overflow] {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-mask: linear-gradient(180deg, #000 calc(100% - .5em), transparent);
  mask: linear-gradient(180deg, #000 calc(100% - .5em), transparent);
}
.filepond--list-scroller::-webkit-scrollbar {
  background: transparent;
}
.filepond--list-scroller::-webkit-scrollbar:vertical {
  width: 1em;
}
.filepond--list-scroller::-webkit-scrollbar:horizontal {
  height: 0;
}
.filepond--list-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .3);
  border-radius: 99999px;
  border: .3125em solid transparent;
  background-clip: content-box;
}
.filepond--list.filepond--list {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  will-change: transform;
}
.filepond--list {
  left: .75em;
  right: .75em;
}
.filepond--root[data-style-panel-layout~=integrated] {
  width: 100%;
  height: 100%;
  max-width: none;
  margin: 0;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--panel-root,
.filepond--root[data-style-panel-layout~=integrated] .filepond--panel-root {
  border-radius: 0;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--panel-root > *,
.filepond--root[data-style-panel-layout~=integrated] .filepond--panel-root > * {
  display: none;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--drop-label,
.filepond--root[data-style-panel-layout~=integrated] .filepond--drop-label {
  bottom: 0;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--item-panel,
.filepond--root[data-style-panel-layout~=integrated] .filepond--item-panel {
  display: none;
}
.filepond--root[data-style-panel-layout~=compact] .filepond--list-scroller,
.filepond--root[data-style-panel-layout~=integrated] .filepond--list-scroller {
  overflow: hidden;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.filepond--root[data-style-panel-layout~=compact] .filepond--list,
.filepond--root[data-style-panel-layout~=integrated] .filepond--list {
  left: 0;
  right: 0;
  height: 100%;
}
.filepond--root[data-style-panel-layout~=compact] .filepond--item,
.filepond--root[data-style-panel-layout~=integrated] .filepond--item {
  margin: 0;
}
.filepond--root[data-style-panel-layout~=compact] .filepond--file-wrapper,
.filepond--root[data-style-panel-layout~=integrated] .filepond--file-wrapper {
  height: 100%;
}
.filepond--root[data-style-panel-layout~=compact] .filepond--drop-label,
.filepond--root[data-style-panel-layout~=integrated] .filepond--drop-label {
  z-index: 7;
}
.filepond--root[data-style-panel-layout~=circle] {
  border-radius: 99999rem;
  overflow: hidden;
}
.filepond--root[data-style-panel-layout~=circle] > .filepond--panel {
  border-radius: inherit;
}
.filepond--root[data-style-panel-layout~=circle] > .filepond--panel > * {
  display: none;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--file-info,
.filepond--root[data-style-panel-layout~=circle] .filepond--file-status {
  display: none;
}
.filepond--root[data-style-panel-layout~=circle] .filepond--action-edit-item {
  opacity: 1 !important;
  visibility: visible !important;
}
@media not all and (min-resolution:0.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .filepond--root[data-style-panel-layout~=circle] {
      will-change: transform;
    }
  }
}
.filepond--panel-root {
  border-radius: .5em;
  background-color: #f1f0ef;
}
.filepond--panel {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0;
  height: 100% !important;
  pointer-events: none;
}
.filepond-panel:not([data-scalable=false]) {
  height: auto !important;
}
.filepond--panel[data-scalable=false] > div {
  display: none;
}
.filepond--panel[data-scalable=true] {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-color: transparent !important;
  border: none !important;
}
.filepond--panel-bottom,
.filepond--panel-center,
.filepond--panel-top {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
}
.filepond--panel-bottom,
.filepond--panel-top {
  height: .5em;
}
.filepond--panel-top {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none !important;
}
.filepond--panel-top:after {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  bottom: -1px;
  background-color: inherit;
}
.filepond--panel-bottom,
.filepond--panel-center {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: translate3d(0, .5em, 0);
  transform: translate3d(0, .5em, 0);
}
.filepond--panel-bottom {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
}
.filepond--panel-bottom:before {
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  top: -1px;
  background-color: inherit;
}
.filepond--panel-center {
  height: 100px !important;
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 0 !important;
}
.filepond--panel-center:not([style]) {
  visibility: hidden;
}
.filepond--progress-indicator {
  position: static;
  width: 1.25em;
  height: 1.25em;
  color: #fff;
  margin: 0;
  pointer-events: none;
  will-change: transform, opacity;
}
.filepond--progress-indicator svg {
  width: 100%;
  height: 100%;
  vertical-align: top;
  transform-box: fill-box;
}
.filepond--progress-indicator path {
  fill: none;
  stroke: currentColor;
}
.filepond--list-scroller {
  z-index: 6;
}
.filepond--drop-label {
  z-index: 5;
}
.filepond--drip {
  z-index: 3;
}
.filepond--root > .filepond--panel {
  z-index: 2;
}
.filepond--browser {
  z-index: 1;
}
.filepond--root {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1em;
  font-size: 1rem;
  line-height: normal;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
  font-weight: 450;
  text-align: left;
  text-rendering: optimizeLegibility;
  direction: ltr;
  contain: layout style size;
}
.filepond--root * {
  box-sizing: inherit;
  line-height: inherit;
}
.filepond--root :not(text) {
  font-size: inherit;
}
.filepond--root[data-disabled] {
  pointer-events: none;
}
.filepond--root[data-disabled] .filepond--list-scroller {
  pointer-events: all;
}
.filepond--root[data-disabled] .filepond--list {
  pointer-events: none;
}
.filepond--root .filepond--drop-label {
  min-height: 4.75em;
}
.filepond--root .filepond--list-scroller {
  margin-top: 1em;
  margin-bottom: 1em;
}
.filepond--root .filepond--credits {
  position: absolute;
  right: 0;
  opacity: .175;
  line-height: .85;
  font-size: 11px;
  color: inherit;
  text-decoration: none;
  z-index: 3;
  bottom: -14px;
}
.filepond--root .filepond--credits[style] {
  top: 0;
  bottom: auto;
  margin-top: 14px;
}

/* node_modules/trix/dist/trix.css */
trix-editor {
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 0;
  padding: 0.4em 0.6em;
  min-height: 5em;
  outline: none;
}
trix-toolbar * {
  box-sizing: border-box;
}
trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
}
trix-toolbar .trix-button-group {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #bbb;
  border-top-color: #ccc;
  border-bottom-color: #888;
  border-radius: 3px;
}
trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 1.5vw;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0;
  }
}
trix-toolbar .trix-button-group-spacer {
  flex-grow: 1;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group-spacer {
    display: none;
  }
}
trix-toolbar .trix-button {
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 0.5em;
  margin: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: transparent;
}
trix-toolbar .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}
trix-toolbar .trix-button.trix-active {
  background: #cbeefa;
  color: black;
}
trix-toolbar .trix-button:not(:disabled) {
  cursor: pointer;
}
trix-toolbar .trix-button:disabled {
  color: rgba(0, 0, 0, 0.125);
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button {
    letter-spacing: -0.01em;
    padding: 0 0.3em;
  }
}
trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 1.6em;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon {
    height: 2em;
    max-width: calc(0.8em + 3.5vw);
  }
}
trix-toolbar .trix-button--icon::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon::before {
    right: 6%;
    left: 6%;
  }
}
trix-toolbar .trix-button--icon.trix-active::before {
  opacity: 1;
}
trix-toolbar .trix-button--icon:disabled::before {
  opacity: 0.125;
}
trix-toolbar .trix-button--icon-attach::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5a4%204%200%201%201-8%200V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5a4%204%200%201%200-8%200v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E);
  top: 8%;
  bottom: 4%;
}
trix-toolbar .trix-button--icon-bold::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.6%2011.8c1-.7%201.6-1.8%201.6-2.8a4%204%200%200%200-4-4H7v14h7c2.1%200%203.7-1.7%203.7-3.8%200-1.5-.8-2.8-2.1-3.4zM10%207.5h3a1.5%201.5%200%201%201%200%203h-3v-3zm3.5%209H10v-3h3.5a1.5%201.5%200%201%201%200%203z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-italic::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M10%205v3h2.2l-3.4%208H6v3h8v-3h-2.2l3.4-8H18V5h-8z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-link::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M9.88%2013.7a4.3%204.3%200%200%201%200-6.07l3.37-3.37a4.26%204.26%200%200%201%206.07%200%204.3%204.3%200%200%201%200%206.06l-1.96%201.72a.91.91%200%201%201-1.3-1.3l1.97-1.71a2.46%202.46%200%200%200-3.48-3.48l-3.38%203.37a2.46%202.46%200%200%200%200%203.48.91.91%200%201%201-1.3%201.3z%22%2F%3E%3Cpath%20d%3D%22M4.25%2019.46a4.3%204.3%200%200%201%200-6.07l1.93-1.9a.91.91%200%201%201%201.3%201.3l-1.93%201.9a2.46%202.46%200%200%200%203.48%203.48l3.37-3.38c.96-.96.96-2.52%200-3.48a.91.91%200%201%201%201.3-1.3%204.3%204.3%200%200%201%200%206.07l-3.38%203.38a4.26%204.26%200%200%201-6.07%200z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-strike::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.73%2014l.28.14c.26.15.45.3.57.44.12.14.18.3.18.5%200%20.3-.15.56-.44.75-.3.2-.76.3-1.39.3A13.52%2013.52%200%200%201%207%2014.95v3.37a10.64%2010.64%200%200%200%204.84.88c1.26%200%202.35-.19%203.28-.56.93-.37%201.64-.9%202.14-1.57s.74-1.45.74-2.32c0-.26-.02-.51-.06-.75h-5.21zm-5.5-4c-.08-.34-.12-.7-.12-1.1%200-1.29.52-2.3%201.58-3.02%201.05-.72%202.5-1.08%204.34-1.08%201.62%200%203.28.34%204.97%201l-1.3%202.93c-1.47-.6-2.73-.9-3.8-.9-.55%200-.96.08-1.2.26-.26.17-.38.38-.38.64%200%20.27.16.52.48.74.17.12.53.3%201.05.53H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-quote::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-code::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M4%204a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm4%203h14v-2H8v2zm0-6h14v-2H8v2zm0-8v2h14V5H8z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-number-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-undo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.6%200-5%201-6.9%202.6L2%207v9h9l-3.6-3.6A8%208%200%200%201%2020%2016l2.4-.8a10.5%2010.5%200%200%200-10-7.2z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-redo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.4%2010.6a10.5%2010.5%200%200%200-16.9%204.6L4%2016a8%208%200%200%201%2012.7-3.6L13%2016h9V7l-3.6%203.6z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-8.3-.3l2.8%202.9L6%2014.2%204%2012l2-2-1.4-1.5L1%2012l.7.7zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-6.9-1L1%2014.2l1.4%201.4L6%2012l-.7-.7-2.8-2.8L1%209.9%203.1%2012zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}
trix-toolbar .trix-dialogs {
  position: relative;
}
trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5;
}
trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  padding: 0.5em 0.8em;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
trix-toolbar .trix-input--dialog.validate:invalid {
  box-shadow: #F00 0px 0px 1.5px 1px;
}
trix-toolbar .trix-button--dialog {
  font-size: inherit;
  padding: 0.5em;
  border-bottom: none;
}
trix-toolbar .trix-dialog--link {
  max-width: 600px;
}
trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline;
}
trix-toolbar .trix-dialog__link-fields .trix-input {
  flex: 1;
}
trix-toolbar .trix-dialog__link-fields .trix-button-group {
  flex: 0 0 content;
  margin: 0;
}
trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection,
trix-editor [data-trix-mutable] ::-moz-selection {
  background: none;
}
trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection,
trix-editor [data-trix-mutable] ::selection {
  background: none;
}
trix-editor [data-trix-mutable].attachment__caption-editor:focus::-moz-selection {
  background: highlight;
}
trix-editor [data-trix-mutable].attachment__caption-editor:focus::selection {
  background: highlight;
}
trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent;
}
trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight;
}
trix-editor .attachment {
  position: relative;
}
trix-editor .attachment:hover {
  cursor: default;
}
trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text;
}
trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}
trix-editor .attachment__progress[value="100"] {
  opacity: 0;
}
trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center;
}
trix-editor .trix-button-group {
  display: inline-flex;
}
trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
}
trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}
trix-editor .trix-button.trix-active {
  background: #cbeefa;
}
trix-editor .trix-button:not(:disabled) {
  cursor: pointer;
}
trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}
trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.4L17.6%205%2012%2010.6%206.4%205%205%206.4l5.6%205.6L5%2017.6%206.4%2019l5.6-5.6%205.6%205.6%201.4-1.4-5.6-5.6z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}
trix-editor .trix-button--remove:hover {
  border-color: #333;
}
trix-editor .trix-button--remove:hover::before {
  opacity: 1;
}
trix-editor .attachment__metadata-container {
  position: relative;
}
trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}
trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap;
}
.trix-content {
  line-height: 1.5;
}
.trix-content * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.trix-content h1 {
  font-size: 1.2em;
  line-height: 1.2;
}
.trix-content blockquote {
  border: 0 solid #ccc;
  border-left-width: 0.3em;
  margin-left: 0.3em;
  padding-left: 0.6em;
}
.trix-content [dir=rtl] blockquote,
.trix-content blockquote[dir=rtl] {
  border-width: 0;
  border-right-width: 0.3em;
  margin-right: 0.3em;
  padding-right: 0.6em;
}
.trix-content li {
  margin-left: 1em;
}
.trix-content [dir=rtl] li {
  margin-right: 1em;
}
.trix-content pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto;
}
.trix-content img {
  max-width: 100%;
  height: auto;
}
.trix-content .attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.trix-content .attachment a {
  color: inherit;
  text-decoration: none;
}
.trix-content .attachment a:hover,
.trix-content .attachment a:visited:hover {
  color: inherit;
}
.trix-content .attachment__caption {
  text-align: center;
}
.trix-content .attachment__caption .attachment__name + .attachment__size::before {
  content: " \b7  ";
}
.trix-content .attachment--preview {
  width: 100%;
  text-align: center;
}
.trix-content .attachment--preview .attachment__caption {
  color: #666;
  font-size: 0.9em;
  line-height: 1.2;
}
.trix-content .attachment--file {
  color: #333;
  line-height: 1;
  margin: 0 2px 2px 2px;
  padding: 0.4em 1em;
  border: 1px solid #bbb;
  border-radius: 5px;
}
.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.trix-content .attachment-gallery .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}
.trix-content .attachment-gallery.attachment-gallery--2 .attachment,
.trix-content .attachment-gallery.attachment-gallery--4 .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

/* packages/forms/resources/css/components/color-picker.css */
.filament-forms-color-picker-preview {
  background-image: repeating-linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%, #aaa), repeating-linear-gradient(45deg, #aaa 25%, #fff 25%, #fff 75%, #aaa 75%, #aaa);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
}
.filament-forms-color-picker-preview::after {
  @apply absolute inset-0;
  content: "";
  background: var(--color);
}

/* packages/forms/resources/css/components/file-upload.css */
.filepond--root {
  @apply mb-0 overflow-hidden;
}
.filepond--panel-root {
  @apply rounded-lg border border-gray-300 bg-white shadow-sm;
}
.filepond--drip-blob {
  @apply bg-gray-900;
}
.dark .filepond--drop-label {
  @apply text-gray-200;
}
.dark .filepond--panel-root {
  @apply border-gray-600 bg-gray-700 text-white;
}
.dark .filepond--drip-blob {
  @apply bg-gray-100;
}
.filepond--root[data-style-panel-layout=compact] .filepond--item {
  @apply mb-0.5;
}
.filepond--root[data-style-panel-layout=compact] .filepond--drop-label label {
  @apply text-sm;
}
.filepond--root[data-style-panel-layout=compact] .filepond--drop-label {
  min-height: 2.625em;
}
.filepond--root[data-style-panel-layout=compact] .filepond--file {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.filepond--root[data-style-panel-layout=grid] .filepond--item {
  width: calc(50% - 0.5em);
  display: inline;
}
@media screen(lg) {
  .filepond--root[data-style-panel-layout=grid] .filepond--item {
    width: calc(33.33% - 0.5em);
  }
}
.filepond--download-icon {
  @apply pointer-events-auto mr-1 inline-block h-4 w-4 bg-white align-bottom hover:bg-white/70;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItZG93bmxvYWQiPjxwYXRoIGQ9Ik0yMSAxNXY0YTIgMiAwIDAgMS0yIDJINWEyIDIgMCAwIDEtMi0ydi00Ij48L3BhdGg+PHBvbHlsaW5lIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5bGluZT48bGluZSB4MT0iMTIiIHkxPSIxNSIgeDI9IjEyIiB5Mj0iMyI+PC9saW5lPjwvc3ZnPg==);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItZG93bmxvYWQiPjxwYXRoIGQ9Ik0yMSAxNXY0YTIgMiAwIDAgMS0yIDJINWEyIDIgMCAwIDEtMi0ydi00Ij48L3BhdGg+PHBvbHlsaW5lIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5bGluZT48bGluZSB4MT0iMTIiIHkxPSIxNSIgeDI9IjEyIiB5Mj0iMyI+PC9saW5lPjwvc3ZnPg==);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}
.filepond--open-icon {
  @apply pointer-events-auto mr-1 inline-block h-4 w-4 bg-white align-bottom hover:bg-white/70;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJoLTYgdy02IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjIiPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTEwIDZINmEyIDIgMCAwMC0yIDJ2MTBhMiAyIDAgMDAyIDJoMTBhMiAyIDAgMDAyLTJ2LTRNMTQgNGg2bTAgMHY2bTAtNkwxMCAxNCIgLz4KPC9zdmc+Cg==);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJoLTYgdy02IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjIiPgogIDxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTEwIDZINmEyIDIgMCAwMC0yIDJ2MTBhMiAyIDAgMDAyIDJoMTBhMiAyIDAgMDAyLTJ2LTRNMTQgNGg2bTAgMHY2bTAtNkwxMCAxNCIgLz4KPC9zdmc+Cg==);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

/* packages/forms/resources/css/components/rich-editor.css */
.dark .trix-button {
  @apply border-gray-600 bg-gray-700 text-gray-200;
}
.dark .trix-button-group {
  @apply border-gray-600;
}
.dark trix-toolbar .trix-dialog {
  @apply border-t-2 border-gray-900 bg-gray-800;
  box-shadow: 0 0.3em 1em theme("colors.gray.900");
}
.dark trix-toolbar .trix-input {
  @apply border-gray-600 bg-gray-700;
}
.dark trix-toolbar .trix-button:not(:first-child) {
  @apply border-l border-gray-600;
}
trix-toolbar .filament-forms-rich-editor-component-toolbar-button.trix-active {
  @apply bg-primary-600 text-white;
}
[dir=rtl] trix-editor.prose :where(ul):not(:where([class~="not-prose"] *)),
[dir=rtl] trix-editor.prose :where(ol):not(:where([class~="not-prose"] *)) {
  padding-right: 1.625em !important;
  padding-left: 0em !important;
}
[dir=rtl] trix-editor.prose :where(ul > li):not(:where([class~="not-prose"] *)),
[dir=rtl] trix-editor.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-right: 0.375em !important;
  padding-left: 0em !important;
}

/* packages/forms/resources/css/components/select.css */
.choices {
  @apply relative outline-none;
}
.choices:last-child {
  @apply mb-0;
}
.choices.is-open {
  @apply overflow-visible;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  @apply select-none;
}
.choices.is-disabled .choices__item {
  @apply pointer-events-none cursor-not-allowed opacity-70;
}
.choices [hidden] {
  display: none !important;
}
.choices[data-type*=select-one] {
  @apply cursor-pointer;
}
.choices[data-type*=select-one] .has-no-choices {
  display: none;
}
.choices[data-type*=select-one] .choices__input {
  @apply m-0 block w-full border-b p-2;
}
.dark .choices[data-type*=select-one] .choices__input {
  @apply bg-gray-700;
}
.choices[data-type*=select-multiple] .choices__inner {
  @apply cursor-text;
}
.choices__inner {
  @apply inline-block w-full rounded-lg border border-gray-300 bg-white bg-no-repeat px-3 py-2 align-top shadow-sm transition duration-75;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}
.dark .choices__inner {
  @apply border-gray-600 bg-gray-700 text-white;
}
.choices--error .choices__inner {
  @apply border-danger-600 ring-danger-600;
}
.dark .choices--error .choices__inner {
  @apply border-danger-400 ring-danger-400;
}
[dir=rtl] .choices__inner {
  background-position: left 0.5rem center;
}
.is-focused .choices__inner,
.is-open .choices__inner {
  @apply border-primary-500 outline-none ring-1 ring-inset ring-primary-500;
}
.choices__list {
  @apply m-0 list-none pl-0;
}
.choices__list--single {
  @apply inline-block w-full pr-12;
}
[dir=rtl] .choices__list--single {
  @apply pl-12 pr-0;
}
.choices__list--single .choices__item {
  @apply w-full;
}
.choices__list--multiple {
  @apply flex flex-wrap gap-1 pr-6;
}
[dir=rtl] .choices__list--multiple {
  @apply pl-6 pr-0;
}
.choices__list--multiple:not(:empty) {
  @apply mb-1 flex;
}
.choices__list--multiple .choices__item {
  @apply box-border inline-flex cursor-pointer items-center justify-between gap-2 space-x-1 break-all rounded-lg bg-primary-500/10 px-2 py-0.5 text-sm font-medium tracking-tight text-primary-700;
}
.dark .choices__list--multiple .choices__item {
  @apply text-primary-500;
}
[dir=rtl] .choices__list--multiple .choices__item {
  @apply space-x-reverse break-normal;
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  @apply invisible absolute top-full z-[1] mt-2 w-full overflow-hidden break-words rounded-lg border border-gray-300 bg-white shadow-sm will-change-[visibility];
}
.dark .choices__list--dropdown,
.dark .choices__list[aria-expanded] {
  @apply border-gray-600 bg-gray-700;
}
.is-active.choices__list--dropdown,
.is-active.choices__list[aria-expanded] {
  @apply visible z-10;
}
.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
  @apply relative max-h-60 overflow-auto will-change-scroll;
}
.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  @apply relative px-3 py-2;
}
[dir=rtl] .choices__list--dropdown .choices__item,
[dir=rtl] .choices__list[aria-expanded] .choices__item {
  @apply text-right;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  @apply bg-primary-600 text-white;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  @apply opacity-70;
}
.choices__item {
  @apply cursor-default;
}
.choices__item--selectable {
  @apply cursor-pointer;
}
.choices__item--disabled {
  @apply pointer-events-none cursor-not-allowed select-none opacity-70;
}
.choices__placeholder {
  @apply opacity-70;
}
.choices__button {
  @apply cursor-pointer appearance-none border-0 bg-transparent bg-center bg-no-repeat outline-none;
  text-indent: -9999px;
}
.choices[data-type*=select-one] .choices__button {
  @apply absolute right-0 mr-9 h-4 w-4 p-0 opacity-60;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 0.7em 0.7em;
  top: calc(50% - 0.55em);
}
.dark .choices[data-type*=select-one] .choices__button {
  @apply opacity-30;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}
[dir=rtl] .choices[data-type*=select-one] .choices__button {
  @apply left-0 right-auto ml-9 mr-0;
}
.choices[data-type*=select-one] .choices__button:hover,
.choices[data-type*=select-one] .choices__button:focus {
  @apply opacity-75;
}
.dark .choices[data-type*=select-one] .choices__button:hover,
.dark .choices[data-type*=select-one] .choices__button:focus {
  @apply opacity-60;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  @apply hidden;
}
.choices[data-type*=select-multiple] .choices__button {
  @apply inline-block h-3 w-3 text-primary-700 opacity-60;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 0.6em 0.6em;
}
.dark .choices[data-type*=select-multiple] .choices__button {
  @apply opacity-75;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}
.choices[data-type*=select-multiple] .choices__button:hover,
.choices[data-type*=select-multiple] .choices__button:focus {
  @apply opacity-75;
}
.dark .choices[data-type*=select-multiple] .choices__button:hover,
.dark .choices[data-type*=select-multiple] .choices__button:focus {
  @apply opacity-100;
}
.choices__list--dropdown .choices__input {
  padding: 0.5rem 0.75rem !important;
  border-top-width: 0 !important;
  border-left-width: 0 !important;
  border-bottom-width: 1px !important;
  border-right-width: 0 !important;
  border-color: rgb(209 213 219) !important;
}
.dark .choices__list--dropdown .choices__input {
  @apply placeholder-gray-300;
  border-color: rgb(75 85 99) !important;
}
.choices__input {
  @apply inline-block max-w-full border-none outline-none;
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 0 !important;
}
.choices__input:focus {
  outline-color: transparent !important;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}
.choices__input::-webkit-search-decoration,
.choices__input::-webkit-search-cancel-button,
.choices__input::-webkit-search-results-button,
.choices__input::-webkit-search-results-decoration {
  @apply hidden;
}
.choices__input::-ms-clear,
.choices__input::-ms-reveal {
  @apply hidden h-0 w-0;
}

/* packages/forms/resources/css/components/tags-input.css */
.webkit-calendar-picker-indicator\:opacity-0::-webkit-calendar-picker-indicator {
  @apply opacity-0;
}
